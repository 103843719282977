import Vue from "vue";
import VueRouter from "vue-router";
import goods from "../views/GoodsView.vue";
// import Index from "../views/Index.vue";
import PayFrame from "../views/PayFrame.vue";
import PaySuccess from "../views/PaySuccess.vue";
import Account from "../views/Account.vue";
import About from "../views/terms-policies/about.vue";
import PrivacyPolicy from "../views/terms-policies/privacy-policy.vue";
import TermConditions from "../views/terms-policies/term-conditions.vue";
import RefundCancellation from "../views/terms-policies/refund-cancellation.vue";
import ContactUs from "../views/terms-policies/contact-us.vue";
Vue.use(VueRouter);

const routes = [
  
  {
    path: "/account",
    component: Account,
  },
  {
    path: "/goods",
    component: goods,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/term-conditions",
    component: TermConditions,
  },
  {
    path: "/refund-cancellation",
    component: RefundCancellation,
  },
  {
    path: "/contact-us",
    component: ContactUs,
  },
  {
    path: "/",
    component: Account,
  },
  {
    path: "/pay-frame",
    component: PayFrame,
  },
  {
    path: "/pay-success",
    component: PaySuccess,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
