<template>
  <div class="refund-cancellation-view app-adapted-wrap">
    <h2 class="rcv-title">Cancellation & Refund Policy</h2>
    <p class="rcv-paragraph" v-for="(item, index) in paragraphs" :key="index">
      {{ item }}
    </p>
  </div>
</template>

<script type="text/javascript">
const paragraphs = [
  'These items are virtual items, so once sold, it cannot be refunded or cancelled. Also refund and cancellation system is not available in application. If you purchased something on the platform and did not get service, in this situation, we can check and give your service manually.',
];
export default {
  name: 'refund-cancellation-view',
  data() {
    return {
      paragraphs,
    };
  }
};
</script>

<style lang="less" scoped>
.refund-cancellation-view {
  overflow: hidden;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.rcv-title {
  font-size: 0.4rem;
  text-align: center;
  margin-bottom: 0.5rem;
}
.rcv-paragraph {
  font-size: 0.32rem;
  line-height: 140%;
  margin-bottom: 0.3rem;
}
</style>
