<template>
  <div class="contact-us-view app-adapted-wrap">
    <h2 class="cuv-title">Contact us</h2>
    <p class="cuv-paragraph" v-for="(item, index) in paragraphs" :key="index">
      {{ item }}
    </p>
  </div>
</template>

<script type="text/javascript">
const paragraphs = [
  'Company Name: Othala solutions pvt ltd',
  'Address: M-2/13 First Floor, Mohan Park, Model Town-3, Delhi 110009',
  'Email: solutionsothala@gmail.com',
];
export default {
  name: 'contact-us-view',
  data() {
    return {
      paragraphs,
    };
  }
};
</script>

<style lang="less" scoped>
.contact-us-view {
  overflow: hidden;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.cuv-title {
  font-size: 0.4rem;
  margin-bottom: 0.5rem;
}
.cuv-paragraph {
  font-size: 0.32rem;
  line-height: 140%;
  margin-bottom: 0.3rem;
}
</style>
