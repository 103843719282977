<template>
  <div class="accout-view">
    <div class="av-inner-content">
      <div class="av-sign-in" />
      <div class="av-input-wrap">
        <div class="av-input">
          <van-field v-model="account" placeholder="Please enter your account" />
        </div>
        <div :class="['av-input-btn', !account ? 'disabled' : '']" @click="handleSubmit">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <more-menus />
  </div>
</template>

<script type="text/javascript">
import { checkAccount } from '@/api/user';
import { Icon, Field, Toast } from 'vant';

import MoreMenus from '@/components/MoreMenus.vue';

export default {
  components: {
    'van-icon': Icon,
    'van-field': Field,
    'more-menus': MoreMenus,
  },
  data() {
    return {
      account: '',
    };
  },
  methods: {
    handleSubmit() {
      if (!this.account) {
        return;
      }
      checkAccount({ account: this.account }).then((res) => {
        if (res.code == 4) {
          Toast('This account does not \n exist');
        }
        if (res.code != 0) {
          return;
        }
        localStorage.setItem('pay_info', JSON.stringify({ account: this.account }));
        this.$router.push({ path: '/goods', query: { account: this.account } });
      });
    },
  },
};
</script>

<style scoped lang="less">
.accout-view {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #ffffff;
  background: url('../assets/pay_background_pc.gif') no-repeat center / cover;
}
.av-inner-content {
  width: 9.7rem;
  margin: 7.56rem auto 0 auto;
}
.av-sign-in {
  margin: 0 auto;
  width: 3.04rem;
  height: 1.24rem;
  margin-bottom: 1.6rem;
  background: url('../assets/sign_in_pc.png') no-repeat center / 100%;
}
.av-input-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.av-input {
  flex: 1;
  width: 100%;
  height: 1.16rem;
  background: url('../assets/input_background.png') no-repeat center / 100%;
  box-shadow: -0.04rem 0.06rem 0 0 rgba(55,55,55,0.77);
}
.av-input :deep(.van-cell) {
  padding: 0.35rem 0.4rem;
  background-color: transparent;

  input {
    font-size: 0.4rem;
  }
}
.av-input-btn {
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  color: #FFFFFF;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #1441f4;
  box-shadow: -0.04rem 0.06rem 0 0 rgba(55,55,55,0.77);

  &:hover {
    background-color: darken(#1441f4, 5%);
  }
  &:active {
    background-color: darken(#1441f4, 10%);
  }
}
.av-input-btn.disabled {
  background-color: #4987ef;
}
.av-input-btn :deep(.van-icon) {
  font-size: 0.76rem;
}
</style>

<style scoped lang="less">
@media screen and (max-width: 500px) {
  .accout-view {
    background: url('../assets/pay_background_raw.gif') no-repeat center / cover;
  }
  .av-inner-content {
    width: 5.88rem;
    margin: 8.2rem auto 0 auto;
  }
  .av-sign-in {
    width: 2.04rem;
    height: 0.84rem;
    margin: 0 0 0.8rem 0;
    background: url('../assets/sign_in.png') no-repeat center / 100%;
  }
  .av-input {
    flex: 1;
    width: 100%;
    height: 0.7rem;
    background: url('../assets/input_background.png') no-repeat center / 100%;
    box-shadow: none;
  }
  .av-input :deep(.van-cell) {
    padding: 0.11rem 0.32rem;
    background-color: transparent;

    input {
      font-size: 0.24rem;
    }
  }
  .av-input-btn {
    width: 1rem;
    height: 1rem;
    box-shadow: none;
  }
  .av-input-btn :deep(.van-icon) {
    font-size: 0.56rem;
  }
}
</style>
