<template>
  <div class="vip-goods-container">
    <div v-for="(item, index) in vipGoodsInfo" :key="index" :class="['vip-item-container', activeIndex == index ? 'vip-item-active' : '']" @click="selectedGoods(item, index)">
      <div class="vip-item-left">
        <div class="vip-item-info">
          <div class="vip-item-name">{{ item.name }}</div>
          <div class="vip-item-desc">{{ item.monthPrice }}</div>
        </div>
        <div class="vip-rebate-container" v-if="item.rebate">
          {{ item.rebate }}
        </div>
      </div>
      <div class="vip-price-container" v-if="item.showPrice == 1">
        {{ item.symbol }}{{ item.price }}
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  props: ['vipGoodsInfo', 'copywriting'],
  data() {
    return {
      activeIndex: -1,
    };
  },
  methods: {
    selectedGoods(goods, index) {
      this.activeIndex = index;
      this.$parent.openPay(goods);
    },
  },
};
</script>

<style lang="less" scoped>
.vip-goods-container {
  margin-top: 68px;
}
.vip-item-container {
  width: 100%;
  height: 110px;
  border-radius: 20px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 2px solid transparent;

  &.vip-item-active {
    border-color: #fdd46b;
  }
}
.vip-item-left {
  display: flex;
  align-items: center;
}
.vip-item-name {
  color: #333333;
  font: 600 20px/24px PingFang HK-Semibold, PingFang HK;
}
.vip-item-desc {
  margin-top: 6px;
  color: #A0A0A0;
  font: 600 20px/24px PingFang HK-Semibold, PingFang HK;
}
.vip-rebate-container {
  border-radius: 10px;
  text-align: center;
  padding: 0 18px;
  box-sizing: border-box;
  background-color: #c9afff;
  color: #333333;
  height: 40px;
  margin-left: 36px;
  font: 500 20px/40px PingFang HK-Regular, PingFang HK;
}
.vip-price-container {
  width: 124px;
  height: 52px;
  text-align: center;
  border-radius: 12px;
  background-color: #fdd46b;
  color: #333333;
  cursor: pointer;
  font: 600 20px/52px PingFang HK-Semibold, PingFang HK;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 500px) {
  .vip-goods-container {
    margin-top: 10px;
  }
  .vip-item-container {
    width: 100%;
    height: 1.12rem;
    border-radius: 0.16rem;
    margin-top: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.4rem;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 2px solid transparent;

    &.vip-item-active {
      border-color: #fdd46b;
    }
  }
  .vip-item-name {
    color: #000000;
    font: 600 15px/0.3rem PingFang HK-Semibold, PingFang HK;
  }
  .vip-item-desc {
    color: #999999;
    margin-top: 0.06rem;
    font: 400 12px PingFang HK-Regular, PingFang HK;
  }
  .vip-rebate-container {
    text-align: center;
    background: #c9afff;
    border-radius: 0.16rem;
    color: #000000;
    height: 0.44rem;
    padding: 0 0.18rem;
    margin-left: 0.36rem;
    font: 400 10px/0.44rem PingFang HK-Regular, PingFang HK;
  }
  .vip-price-container {
    width: 1.5rem;
    height: 0.6rem;
    border-radius: 0.3rem;
    text-align: center;
    background-color: #fdd46b;
    color: #000000;
    font: 600 15px/0.6rem PingFang HK-Semibold, PingFang HK;
  }
}
</style>
