<template>
  <div class="app-adapted-wrap">
    <van-loading style="left: 50%; position: absolute; top: 50%" v-if="!load" />
    <div class="main-container" v-if="load">
      <div class="gv-user-wrap">
        <User :user="user" :app-info="appInfo" />
      </div>
      <div class="gv-goods-wrap">
        <Goods :goodsList="goodsList" :payTypes="payTypes" />
      </div>
    </div>
  </div>
</template>
<script>
import { Loading as VanLoading } from 'vant';
import { getGoodsStoreData } from '@/api/goodsStore';

import User from '@/components/User.vue';
import Goods from '@/components/Goods.vue';

export default {
  components: {
    User, Goods, VanLoading,
  },
  data() {
    return {
      load: false,
      goodsList: {
        vip: [], diamond: [],
      },
      user: {
        id: 0, nickname: null, avatarUrl: null, nationalFlagUrl: null,
      },
      appInfo: {
        appName: '', appIcon: '',
      },
      payTypes: [],
    };
  },
  mounted() {
    this.handleFetchGoodsStore();
  },
  methods: {
    handleFetchGoodsStore() {
      return getGoodsStoreData(this.$route.query).then((result) => {
        if (result.code !== 0) {
          return;
        }
        const { user, goods, payTypes, appIcon } = result.data;

        this.user = user;
        this.goodsList = goods;
        this.payTypes = payTypes;
        this.appInfo = { appIcon, appName: user.appName };

        document.title = 'Store'

        this.load = true;
        
        localStorage.setItem('uid', user.id);
      });
    }
  },
};
</script>

<style lang="less" scoped>
.app-adapted-wrap {
  padding: 0;
  background-color: #f5f5f5;
}
.main-container {
  display: flex;
  justify-content: space-between;
}
.gv-user-wrap {
  width: 336px;
}
.gv-goods-wrap {
  flex: 1;
  width: 100%;
  margin-left: 66px;
}
</style>

<style scoped lang="less">
@media screen and (max-width: 500px) {
  .app-adapted-wrap {
    padding: 0 0.3rem !important;
  }
  .main-container {
    display: block;
  }
  .main-container :deep(.user-container) {
    margin: 0 -0.3rem;
  }
  .gv-user-wrap {
    width: 100%;
  }
  .gv-goods-wrap {
    margin-left: 0;
  }
}
</style>