<template>
  <div class="goods-container">
    <div class="goods-container-header">
      <div v-for="(item, index) in goods" :key="index" :class="['goods-container-tab', activeType == item.type ? 'actived' : '', index === 0 ? 'left-radius' : 'right-radius']" @click="activeTab(item, index)">
        <span class="goods-tab-text">{{ item.title }}</span>
      </div>
    </div>
    <vip-goods v-show="showVipGoods" :vipGoodsInfo="vipGoodsInfo" />
    <diamond-goods v-show="showDiamondGoods" :balance="balance" :diamondGoodsInfo="diamondGoodsInfo" />
    <!-- third pay methods list -->
    <pay ref="pay" :payMethods="payMethods" />
  </div>
</template>

<script type="text/javascript">
import Pay from "../components/Pay";
import VipGoods from "../components/goodsDetail/VipGoods.vue";
import DiamondGoods from "../components/goodsDetail/DiamondGoods.vue";
import { GOODS_TYPE } from "@/config";
export default {
  components: { VipGoods, DiamondGoods, Pay },
  props: ["goodsList", "payTypes", "goodsTabConfig"],
  data() {
    return {
      balance: this.$parent.user.diamondBalance,
      diamondGoodsInfo: [],
      vipGoodsInfo: [],
      activeType: "1",
      goods: [
        { title: "Vip", type: 2 },
        { title: "Diamond", type: 1 },
      ],
      payMethods: [],
    };
  },
  methods: {
    activeTab(item, index) {
      this.activeType = item.type;
    },
    openPay(goods) {
      this.$refs["pay"].openPay(goods);
    },
  },
  computed: {
    showVipGoods() {
      return this.activeType == GOODS_TYPE.VIP;
    },
    showDiamondGoods() {
      return this.activeType == GOODS_TYPE.DIAMOND;
    },
  },
  mounted() {
    this.vipGoodsInfo = this.goodsList.vip;
    this.diamondGoodsInfo = this.goodsList.diamond;
    this.payMethods = this.payTypes;
    if (this.vipGoodsInfo.length == 0) {
      this.goods = [];
    }
    if (this.diamondGoodsInfo.length == 0) {
      this.goods = [];
      this.activeType = "2";
    }
  },
};
</script>

<style lang="less" scoped>
.goods-container {
  padding: 50px 0;
  box-sizing: border-box;
}
.goods-container-header {
  display: flex;
}
.goods-container-tab {
  width: 175px;
  height: 64px;
  background: #D9D9D9;
  margin-right: 50px;
  border-radius: 20px;
  color: #A0A0A0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.actived {
    color: #333333;
    background: #fdd46b;
  }
}
.goods-tab-text {
  font: 600 24px PingFang HK-Semibold, PingFang HK;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 500px) {
  .goods-container {
    padding: 0.3rem 0;
  }
  .goods-container-tab {
    width: 100%;
    height: 0.7rem;
    margin-right: 0;
    background-color: #ffffff;
  }
  .goods-container-tab.left-radius {
    border-radius: 0.16rem 0 0 0.16rem;
  }
  .goods-container-tab.right-radius {
    border-radius: 0 0.16rem 0.16rem 0;
  }
  .goods-tab-text {
    font-size: 15px;
  }
}
</style>