<template>
  <div class="privacy-policy-view app-adapted-wrap">
    <h3 class="ppv-title">Privacy policy</h3>
    <p :class="['ppv-paragraph', item.extClass]" v-for="(item, index) in paragraphs" :key="index">
      {{ item.text }}
    </p>
  </div>
</template>

<script type="text/jppvascript">
const paragraphs = [{
  text: 'I. Introduction',
  extClass: ''
}, {
  text: 'We highly value your privacy and are committed to protecting your personal information. Before you use our virtual goods purchasing service, please carefully read and understand this privacy policy. Your use or continued use of our service signifies your agreement to our collection, use, storage, and protection of your personal information as per this privacy policy.',
  extClass: '',
}, {
  text: 'II. Information Collection and Use',
  extClass: '',
}, {
  text: '1. Information Collected: We may collect personal information such as your name, email address, phone number, payment information, and account details.',
  extClass: 'indent',
}, {
  text: '2. Information Use: We use the information collected to provide, maintain, and improve our services, including processing orders, providing customer support, and communication.',
  extClass: 'indent',
}, {
  text: 'III. Information Protection',
  extClass: '',
}, {
  text: 'We will take reasonable measures to protect your personal information from loss, misuse, unauthorized access, disclosure, alteration, or destruction.',
  extClass: '',
}, {
  text: 'IV. Information Sharing and Disclosure',
  extClass: '',
}, {
  text: 'Unless we obtain your consent or as otherwise provided in this policy, we will not sell, trade, or otherwise unauthorizedly transfer your personal information.',
  extClass: '',
}, {
  text: 'V. Third-Party Links',
  extClass: '',
}, {
  text: 'Our services may contain links to third-party websites. If you click on these links, you will leppve our services and will need to read and accept the privacy policy of these third-party websites.',
  extClass: '',
}, {
  text: 'VI. User Rights',
  extClass: '',
}, {
  text: 'You can access, correct, or delete your personal information at any time. You can also decide not to provide us with certain personal information, but it may affect your use of our services.',
  extClass: '',
}, {
  text: 'VII. Policy Updates',
  extClass: '',
}, {
  text: 'We may occasionally update this privacy policy. If there are any changes, we will notify you through the website, application, or email.',
  extClass: '',
}, {
  text: 'VIII. Contact Us',
  extClass: '',
}, {
  text: 'If you hppve any questions about this privacy policy, please feel free to contact us at any time.',
  extClass: '',
}, {
  text: 'Thank you for your trust and support. We commit to continually protect your personal information and provide high-quality services.',
  extClass: '',
}, {
  text: 'Last Revised: 05.01.2023',
  extClass: '',
}, {
  text: 'Othala solutions pvt ltd',
  extClass: '',
}];

export default {
  name: 'privacy-policy-view',
  data() {
    return {
      paragraphs,
    };
  }
};
</script>

<style lang="less" scoped>
.privacy-policy-view {
  overflow: hidden;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.ppv-title {
  font-size: 0.4rem;
  margin-bottom: 0.5rem;
}
.ppv-paragraph {
  font-size: 0.32rem;
  line-height: 140%;
  margin-bottom: 0.3rem;

  &.indent {
    text-indent: 0.2rem;
  }
}
</style>

