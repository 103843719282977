<template>
  <div class="term-conditions-view app-adapted-wrap">
    <h2 class="tcv-title">Terms & Conditions</h2>
    <p :class="['tcv-paragraph', item.extClass]" v-for="(item, index) in paragraphs" :key="index">
      {{ item.text }}
    </p>
  </div>
</template>

<script type="text/javascript">
const paragraphs = [{
  text: 'I. Acceptance of Terms',
  extClass: ''
}, {
  text: 'By accessing and/or using our virtual goods purchasing service, you agree to comply with the following terms and conditions (hereafter referred to as "Terms of Use"). If you do not agree with these Terms of Use, please do not use our services.',
  extClass: '',
}, {
  text: 'II. Description of Service',
  extClass: '',
}, {
  text: 'We provide a platform through which users can purchase various virtual goods from around the world, including but not limited to in-game currency, social media app currency, software licenses, and more.',
  extClass: '',
}, {
  text: 'III. User Conduct',
  extClass: '',
}, {
  text: 'You agree not to use our service for any illegal or unethical actions, including but not limited to fraud, infringement of others\' copyrights or trademarks, spreading malicious software or viruses, etc.',
  extClass: '',
}, {
  text: 'IV. Payment and Refunds',
  extClass: '',
}, {
  text: 'All purchases of virtual goods must be paid immediately after placing the order. Once virtual goods are delivered, we will not provide refunds except under special circumstances, which will be decided by us.',
  extClass: '',
}, {
  text: 'V. Disclaimer',
  extClass: '',
}, {
  text: 'We are not responsible for the content or actions of any third-party websites, even if our services may contain links to these sites.',
  extClass: '',
}, {
  text: 'VI. Termination of Service',
  extClass: '',
}, {
  text: 'We reserve the right to terminate your service at any time for any reason, including but not limited to your violation of these Terms of Use.',
  extClass: '',
}, {
  text: 'VII. Amendment of Terms',
  extClass: '',
}, {
  text: 'We reserve the right to amend these Terms of Use at any time. Any amendments will be posted on our website, and your continued use of our services will be considered acceptance of these amendments.',
  extClass: '',
}, {
  text: 'VIII. Contact Us',
  extClass: '',
}, {
  text: 'If you have any questions about these Terms of Use, please feel free to contact us at any time.',
  extClass: '',
}, {
  text: 'Last Revised: 05.01.2023',
  extClass: '',
}, {
  text: 'Othala solutions pvt ltd',
  extClass: '',
}];

export default {
  name: 'term-conditions-view',
  data() {
    return {
      paragraphs,
    };
  }
};
</script>

<style lang="less" scoped>
.term-conditions-view {
  overflow: hidden;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.tcv-title {
  font-size: 0.4rem;
  margin-bottom: 0.5rem;
}
.tcv-paragraph {
  font-size: 0.32rem;
  line-height: 140%;
  margin-bottom: 0.3rem;

  &.regard {
    margin-bottom: 0rem;
  }
}
</style>
