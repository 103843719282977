<template>
  <van-popup v-model="showPay" closeable round position="bottom" :style="{ maxHeight:'60%',paddingTop: '16px' }">
    <van-row type="flex" align="center" justify="center" style="margin-bottom: 20px">
      <van-col>
        <span class="pay-view-title">Select Payment Method</span>
      </van-col>
    </van-row>
    <van-row style="margin-bottom: 25px; padding: 0 18px;" type="flex" align="center" justify="space-between" v-for="(item, index) in payMethods" :key="index" @click="selectPay(item, index)">
      <van-col>
        <van-image width="135" height="45" :src="item.icon" />
      </van-col>
      <van-col>
        <div :class="['check-btn', activeIndex == index ? 'active' : '']"></div>
      </van-col>
    </van-row>
    <van-row type="flex" align="center" justify="center" :style="{marginBottom: '15px'}">
      <van-col offset="1">
        <div class="pay-confirm-btn" @click="jumpPayPage">
          <span class="pay-confirm-text">Confirm</span>
        </div>
      </van-col>
    </van-row>
  </van-popup>
</template>
<script>
import Vue from "vue";
import { getPayUrl } from "../api/goodsStore";
import { Row as VanRow, Col as VanCol, Popup as VanPopup, Image as VanImage, Toast } from "vant";

Vue.use(Toast);

export default {
  props: ["payMethods"],
  components: {
    VanPopup, VanRow, VanCol, VanImage,
  },
  data() {
    return {
      activeIndex: -1,
      selectedGoods: undefined,
      showPay: false,
      selectedPay: undefined,
      errorText:"Network error, click again"
    };
  },
  methods: {
    openPay(goods) {
      if(!(this.payMethods.length>0)){
        Toast.fail(this.errorText);
        return;
      }
      this.activeIndex = 0;
      this.selectedGoods = undefined;
      this.selectedPay = this.payMethods[0];
      this.selectedGoods = goods;
      this.showPay = true;
    },
    selectPay(pay, index) {
      this.activeIndex = index;
      this.selectedPay = pay;
    },
    jumpPayPage() {
      if (this.selectedPay && this.selectedGoods) {
        console.info(
          `pay method ${this.selectedPay.id} ${this.selectedPay.type} openWith ${this.selectedPay.openWith} goods ${this.selectedGoods.id}`
        );
        const params = {
          aaId: this.$route.query.aaId,
          goodsId: this.selectedGoods.id,
          payType: this.selectedPay.id,
        };
        const { openWith } = this.selectedPay;
        getPayUrl(params).then((res) => {
          if (res.code) {
            console.error("param", params, "error", res);
            Toast.fail(this.errorText);
            return;
          }
          const { paymentUrl } = res.data;
          console.info("create payment url", paymentUrl);
          if (this.$route.query["page-type"] == 1  && openWith == 2) {
            try {
              nativeApi.openBrowsable(paymentUrl);
            } catch (e) {
              console.error("open with webview error", e);
            }
          } else {
            // window.location.href = paymentUrl;
            this.$router.push({ path: "/pay-frame",query:{url: paymentUrl} })
          }
        });
      }
    },
  },
  mounted() {
  },
};
</script>

<style>
.pay-view-title {
  width: 167px;
  height: 15px;
  font-size: 15px;
  font-family: PingFang HK-Regular, PingFang HK;
  font-weight: 400;
  color: #000000;
  line-height: 15px;
}
.check-btn {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #999999;
}
.check-btn.active {
  background: url("../assets/check.png") no-repeat center / cover;
}
.pay-confirm-btn {
  width: 150px;
  height: 40px;
  background: #fdd46b;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.pay-confirm-text {
  width: 57px;
  height: 15px;
  font-size: 15px;
  font-family: PingFang HK-Semibold, PingFang HK;
  font-weight: 600;
  color: #000000;
  line-height: 15px;
}
</style>
