import { render, staticRenderFns } from "./VipGoods.vue?vue&type=template&id=53c0611b&scoped=true&"
import script from "./VipGoods.vue?vue&type=script&lang=js&"
export * from "./VipGoods.vue?vue&type=script&lang=js&"
import style0 from "./VipGoods.vue?vue&type=style&index=0&id=53c0611b&prod&lang=less&scoped=true&"
import style1 from "./VipGoods.vue?vue&type=style&index=1&id=53c0611b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c0611b",
  null
  
)

export default component.exports