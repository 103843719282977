<template>
  <div class="about-view app-adapted-wrap">
    <h2 class="av-title">About us</h2>
    <p :class="['av-paragraph', item.extClass]" v-for="(item, index) in paragraphs" :key="index">
      {{ item.text }}
    </p>
  </div>
</template>

<script type="text/javascript">
const paragraphs = [{
  text: 'Our team is dedicated to providing the best environment for users in the online world, with a primary focus on major emerging markets. We are committed to providing you with a wide variety of the world\'s most popular virtual goods to meet your diverse needs.',
  extClass: ''
}, {
  text: 'Our services mainly include the following parts: in-game currency, social media app currency, software licenses, and more.',
  extClass: '',
}, {
  text: 'We promise that all the goods purchased on behalf are from official channels, ensuring 100% authenticity, and we will deliver the virtual goods to your account as quickly as possible.',
  extClass: '',
}, {
  text: 'Our goal is to provide the highest quality purchasing service, allowing you to enjoy high-quality virtual goods from around the world while simplifying the purchasing process. If you have any questions, or if you have specific virtual goods needs, please feel free to contact us at any time. We are dedicated to serving you.',
  extClass: '',
}, {
  text: 'Best regards,',
  extClass: 'regard',
}, {
  text: 'Othala solutions pvt ltd',
  extClass: 'regard',
}, {
  text: 'Mail：solutionsothala@gmail.com',
  extClass: 'regard',
}];

export default {
  name: 'about-view',
  data() {
    return {
      paragraphs,
    };
  }
};
</script>

<style lang="less" scoped>
.about-view {
  overflow: hidden;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.av-title {
  font-size: 0.4rem;
  margin-bottom: 0.5rem;
}
.av-paragraph {
  font-size: 0.32rem;
  line-height: 140%;
  margin-bottom: 0.3rem;

  &.regard {
    margin-bottom: 0rem;
  }
}
</style>
