<template>
  <div id="container" class="app-adapted-wrap">
    <div class="item">
      <span class="title">Total Payable Amount: </span>
      <span class="amount">{{ amount }}</span>
    </div>

    <div class="item">
      <img width="89" height="32" src="../img/upi.png"/>
    </div>

    <div class="item">
      <hr width="100%" color="#E3E3E3" size="1"/>
    </div>
    <div class="item">
      <span class="click-tip">Please click on the app already installed in your phone</span>
    </div>

    <RadioGroup v-model="payMethodChecked">
      <Radio v-for="(value, key, index) in appsImages" :key="index" :name="key" style="margin-top: 3%;">
        <img v-if="key != 'other' " style="max-height:30px;max-width:110px;" :src="value"/>
        <!--        <span v-if="key == 'other' "-->
        <!--            style="max-height:30px;max-width:110px;font-size: 24px;font-family: Arial;font-weight: bold;">Other</span>-->
      </Radio>
    </RadioGroup>

    <div class="pay-button-container" id="pay">
      <div class="pay-button">
        <span class="pay-btn-text">Pay</span>
      </div>
    </div>

    <div>
      <Popup v-model:show="popShow" :style="{ height: '30%'}" closeable round position="bottom"
             :close-on-click-overlay="false"
             style="box-sizing: border-box; padding-top:10% ">

        <Row style="display: flex; align-items: center; justify-content: center">
          <Col>
            Have you paid successfully?
          </Col>
        </Row>

        <div style="display: flex; align-items: center; justify-content: center;margin-top: 5%;">
          <Row gutter="20" style="width: 80%; display: block; margin: 0 auto">
            <Col span="10" offset="2">
              <Button block style="border-radius: 20px;" @click="()=>{
                this.popShow = false;
              }">
                No
              </Button>
            </Col>
            <Col span="10">
              <Button block type="danger" style="border-radius: 20px;" @click="()=>{
                this.popShow = false;
                this.overlayShow=true;
                this.checkOrder(this.orderId, 0);
                }"
              >
                yes
              </Button>
            </Col>
          </Row>
        </div>
      </Popup>
    </div>
    <Overlay :show="overlayShow">
      <div class="wrapper">
        <Loading type="spinner"/>
      </div>
    </Overlay>
  </div>

</template>

<script>

import {RadioGroup, Radio, Popup, Col, Row, Button, Loading, Overlay, Toast} from 'vant';
import {checkOrderStatus} from "../api/goodsStore";
export default {
  components: {RadioGroup, Radio, Popup, Col, Row, Button, Loading, Overlay, Toast},
  name: "PayFrame",
  data() {
    return {
      link: decodeURIComponent(this.getQueryVariable("deepLink")),
      amount: this.getQueryVariable("amount"),
      orderId: this.getQueryVariable("orderId"),
      appsImages: {
        phonepe: require("../img/apps/phonepe.png"),
        paytm: require("../img/apps/paytm.png"),
        gpay: require("../img/apps/gpay.png"),
        bhim: require("../img/apps/bhim.png"),
        // other: "other",
      },
      apps: {
        phonepe: "phonepe://",
        gpay: "gpay://upi/",
        paytm: "paytmmp://",
        bhim: "upi://",
        amazonpay: "upi://",
        // other: "upi://",
      },
      payMethodChecked: 'phonepe',
      popShow: false,
      overlayShow: false,
    }
  },
  methods: {
    getQueryVariable(variable) {
      var query = this.$route.query['url'].split('?')[1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return 0;
    },
    getById(id) {
      return document.getElementById(id);
    },
    checkOrder(orderId, count) {
      let that = this;
      if (count < 3) {
        checkOrderStatus({orderId: orderId}).then((res) => {
          if (res.code == 0 && res.data?.status == 2) {
            that.$router.push({path: "/pay-success"})
          } else {
            setTimeout(() => {
              that.checkOrder(orderId, ++count);
            }, 1500);
          }
        }).catch(() => {
          Toast.fail("Internal Server Error");
          this.overlayShow = false;
        })
      } else {
        Toast.fail("No order status found");
        this.overlayShow = false;
      }
    },

  },

  mounted() {
    this.getById("pay").addEventListener("click", () => {

      const appName = this.payMethodChecked;
      if (!this.amount || !this.link) {
        return;
      }

      var newLink = this.link.replace("upi://", this.apps[appName]);
      window.location.href = newLink;

      setTimeout(() => {
        this.popShow = true;
      }, 1000);

    });

  }
}
</script>

<style scoped>
#container {
  display: flex;
  flex-direction: column;
  padding-top: 2%;
  overflow: hidden;
}

#container .title {
  font-size: 17px;
  font-family: Arial;
  font-weight: bold;
  color: #000000;
}

#container .amount {
  margin-left: 10px;
  font-size: 31px;
  font-family: Myriad Pro;
  font-weight: bold;
  color: #db7b35;
}

#container .item {
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#container .click-tip {
  font-size: 15px;
  font-family: Arial;
  font-weight: 400;
  color: #232323;
}


.pay-button {
  display: flex;
  width: 205px;
  height: 56px;
  background: #db7b35;
  border-radius: 12px;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.pay-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.pay-btn-text {
  width: 37px;
  height: 21px;
  font-size: 22px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
}


.item-sb span {
  height: 19px;
  font-size: 20px;
  font-family: Arial;
  font-weight: bold;
  color: #000000;
}


.put-box input {
  width: 100%;
  background: #eeeeee;
  text-align: center;
}


.vp-container span {
  font-size: 15px;
  font-family: Kozuka Gothic Pr6N;
  font-weight: normal;
  color: #101010;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


</style>