import axios from "./index";

const getGoodsStoreData = (data) => {
  return axios.request({
    url: "/point/web-goods-store/data",
    data: data,
    method: "post",
  });
};
const getPayUrl = (data) => {
  return axios.request({
    url: "/point/v2/pay/web-quick-create-payment-url",
    data: data,
    method: "post",
  });
};

const checkOrderStatus = (data) => {
  return axios.request({
    url: "/point/order/check-status",
    data: data,
    method: "post",
  });
};


export { getGoodsStoreData ,getPayUrl, checkOrderStatus};
