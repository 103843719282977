<template>
  <div class="user-container">
    <div class="user-detail-wrap">
      <div class="user-avatar">
        <van-image class="user-avatar-img" round :src="user.avatarUrl" />
      </div>
      <div class="user-info">
        <div class="user-info-text">{{ user.nickname }}</div>
        <div class="user-info-text">ID:{{ user.id }}</div>
      </div>
    </div>
    <div class="user-app">
      <div class="user-app-icon">
        <van-image width="100%" height="100%" :src="appInfo.appIcon" />
      </div>
      <div class="user-app-name">{{ appInfo.appName }}</div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { Image } from 'vant';

export default {
  props: ['user', 'appInfo'],
  components: {
    'van-image': Image,
  },
};
</script>

<style lang="less" scoped>
.user-container {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: space-between;
  padding: 90px 0;
  box-sizing: border-box;
}
.user-detail-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.user-avatar-img {
  width: 105px;
  height: 105px;
  margin-bottom: 20px;
  border: 5px solid rgba(205,205,205,0.24);
}
.user-info-text {
  color: #333333;
  text-align: center;
  margin-top: 14px;
  font: 500 16px PingFang TC-Semibold, PingFang TC;
}
.user-app {
  text-align: center;
}
.user-app-icon {
  width: 88px;
  height: 88px;
  margin: 0 auto;
}
.user-app-name {
  color: #333333;
  margin-top: 15px;
  font: 600 24px PingFang TC-Semibold, PingFang TC;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 500px) {
  .user-container {
    height: 2.02rem;
    flex-direction: row;
    padding: 0.12rem 0.54rem 0.4rem 0.3rem;
  }
  .user-detail-wrap {
    flex-direction: row;
  }
  .user-info {
    margin-left: 0.2rem;
  }
  .user-avatar-img {
    margin: 0;
    width: 1.3rem;
    height: 1.3rem;
  }
  .user-info-text {
    margin: 0.1rem 0;
    font-size: 14px;
    text-align: left;
  }
  .user-app-icon {
    width: 0.96rem;
    height: 0.96rem;
  }
  .user-app-name {
    margin-top: 0;
    font-size: 14px;
  }
}

</style>
