var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-adapted-wrap",attrs:{"id":"container"}},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"title"},[_vm._v("Total Payable Amount: ")]),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.amount))])]),_vm._m(0),_vm._m(1),_vm._m(2),_c('RadioGroup',{model:{value:(_vm.payMethodChecked),callback:function ($$v) {_vm.payMethodChecked=$$v},expression:"payMethodChecked"}},_vm._l((_vm.appsImages),function(value,key,index){return _c('Radio',{key:index,staticStyle:{"margin-top":"3%"},attrs:{"name":key}},[(key != 'other' )?_c('img',{staticStyle:{"max-height":"30px","max-width":"110px"},attrs:{"src":value}}):_vm._e()])}),1),_vm._m(3),_c('div',[_c('Popup',{staticStyle:{"box-sizing":"border-box","padding-top":"10%"},style:({ height: '30%'}),attrs:{"closeable":"","round":"","position":"bottom","close-on-click-overlay":false},model:{value:(_vm.popShow),callback:function ($$v) {_vm.popShow=$$v},expression:"popShow"}},[_c('Row',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('Col',[_vm._v(" Have you paid successfully? ")])],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","margin-top":"5%"}},[_c('Row',{staticStyle:{"width":"80%","display":"block","margin":"0 auto"},attrs:{"gutter":"20"}},[_c('Col',{attrs:{"span":"10","offset":"2"}},[_c('Button',{staticStyle:{"border-radius":"20px"},attrs:{"block":""},on:{"click":()=>{
              this.popShow = false;
            }}},[_vm._v(" No ")])],1),_c('Col',{attrs:{"span":"10"}},[_c('Button',{staticStyle:{"border-radius":"20px"},attrs:{"block":"","type":"danger"},on:{"click":()=>{
              this.popShow = false;
              this.overlayShow=true;
              this.checkOrder(this.orderId, 0);
              }}},[_vm._v(" yes ")])],1)],1)],1)],1)],1),_c('Overlay',{attrs:{"show":_vm.overlayShow}},[_c('div',{staticClass:"wrapper"},[_c('Loading',{attrs:{"type":"spinner"}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"width":"89","height":"32","src":require("../img/upi.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('hr',{attrs:{"width":"100%","color":"#E3E3E3","size":"1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',{staticClass:"click-tip"},[_vm._v("Please click on the app already installed in your phone")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-button-container",attrs:{"id":"pay"}},[_c('div',{staticClass:"pay-button"},[_c('span',{staticClass:"pay-btn-text"},[_vm._v("Pay")])])])
}]

export { render, staticRenderFns }