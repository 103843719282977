<template>
  <div class="diamond-goods-container">
    <div class="balance">
      <span>Balance: {{ balance }} </span>
    </div>
    <div v-for="(item, index) in diamondGoodsInfo" :key="index">
      <div style="position: relative" @click="selectedGoods(item, index)">
        <div :class="['diamond-item-container', activeIndex == index ? 'diamond-item-active' : '' ]">
          <div class="diamond-item-left">
            <van-image v-if="item.goodsIcon" class="diamond-icon" fit="cover" round :src="item.goodsIcon" />
            <van-image v-else-if="!$route.query['show-gold']" class="diamond-icon" fit="cover" round :src="require('../../assets/diamond.png')" />
            <van-image v-else class="diamond-icon" fit="cover" round :src="require('../../assets/gold.png')" />
            <div class="diamond-item-number"> {{ item.number }} </div>
            <div class="diamond-rebate-container" v-if="item.rebate">
              <span class="diamond-rebate"> {{ item.rebate }} </span>
            </div>
          </div>
          <div class="diamond-price-container" v-if="item.showPrice == 1">
            <span class="diamond-price-text">{{ item.symbol }}{{ item.price }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { Image as VanImage } from "vant";
export default {
  props: ['balance', 'diamondGoodsInfo'],
  components: {
    VanImage,
  },
  data() {
    return {
      activeIndex: -1,
    };
  },
  methods: {
    selectedGoods(goods, index) {
      this.activeIndex = index;
      this.$parent.openPay(goods);
    },
  },
};
</script>

<style lang="less" scoped>
.balance {
  margin: 20px 0;
  color: #000000;
  font: 600 18px PingFang HK-Semibold, PingFang HK;
}
.diamond-item-container {
  width: 100%;
  height: 86px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  justify-content: space-between;
  border: 2px solid transparent;

  &.diamond-item-active {
    border-color: #fdd46b;
  }
}
.diamond-item-left {
  display: flex;
  align-items: center;
}
.diamond-icon {
  width: 62px;
  height: 62px;
}
.diamond-item-number {
  color: #333333;
  margin-left: 20px;
  font: 500 20px PingFang HK-Semibold, PingFang HK;
}
.diamond-price-container {
  width: 124px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  border-radius: 12px;
  background-color: #fdd46b;
}
.diamond-price-text {
  color: #333333;
  font: 500 20px PingFang HK-Semibold, PingFang HK;
}
.diamond-rebate-container {
  background: #c9afff;
  border-radius: 10px;
  height: 24px;
  color: #000000;
  display: inline-block;
  height: 40px;
  margin-left: 36px;
  padding: 0 18px;
  box-sizing: border-box;
  font: 400 16px/40px PingFang HK-Regular, PingFang HK;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 500px) {
  .balance {
    margin: 0.24rem 0;
    font: 500 16px PingFang HK-Semibold, PingFang HK;
  }
  .diamond-item-container {
    width: 100%;
    height: 1.12rem;
    background: #ffffff;
    border-radius: 0.16rem;
    margin-bottom: 0.24rem;
    display: flex;
    align-items: center;
    padding: 0 0.4rem;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .diamond-item-left {
    display: flex;
    align-items: center;
  }
  .diamond-icon {
    width: 0.62rem;
    height: 0.62rem;
  }
  .diamond-item-number {
    color: #000000;
    margin-left: 0.2rem;
    font: 600 15px PingFang HK-Semibold, PingFang HK;
  }
  .diamond-price-container {
    width: 1.5rem;
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    border-radius: 0.3rem;
    background-color: #fdd46b;

    &.diamond-item-active {
      outline: 2px solid #fdd46b;
    }
  }
  .diamond-price-text {
    height: 15px;
    color: #000000;
    font: 600 15px/15px PingFang HK-Semibold, PingFang HK;
  }
  .diamond-rebate-container {
    background: #c9afff;
    border-radius: 0.16rem;
    height: auto;
    color: #000000;
    display: inline-block;
    padding: 0 0.18rem;
    margin-left: 0.36rem;
    font: 400 10px/0.4rem PingFang HK-Regular, PingFang HK;
  }
}
</style>