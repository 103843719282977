import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false

function isMobile() {
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(window.navigator.userAgent);
}

function forbidMobileScaleEvents() {
  // 禁用双指放大
  document.documentElement.addEventListener('touchstart', (event) => event.touches.length > 1 && event.preventDefault(), { passive: false });

  // 禁用双击放大
  const touchEndAndClickEndTimes = [0, 0];
  const touchEndAndClickEndHandler = (event, index) => {
    const now = Date.now();

    if (now - touchEndAndClickEndTimes[index] <= 300) {
      event.preventDefault();
    }
    touchEndAndClickEndTimes[index] = now;
  };
  document.documentElement.addEventListener('click', (event) => touchEndAndClickEndHandler(event, 1), { passive: false });
  document.documentElement.addEventListener('touchend', (event) => touchEndAndClickEndHandler(event, 0), { passive: false });
}

if (isMobile()) {
  forbidMobileScaleEvents();
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
