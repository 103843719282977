<template>
  <div class="more-menus">
    <div class="mm-icon" @click="handleTriggerPopup(true)"></div>
    <van-popup v-model="visiable" position="right" round class="mm-popup">
      <div class="mm-popup-list">
        <div class="mm-popup-list-item" v-for="(item, index) in menuInfos" :key="index">
          <span @click="handleLinkTo(item.link)">{{ item.text }}</span>
        </div>
      </div>
      <div class="mm-popup-footer">
        <p>Othala Solutions</p>
        <p>Virtualvender Service</p>
      </div>
    </van-popup>
  </div>
</template>

<script type="text/javascript">
import { Popup } from 'vant';

const menuInfos = [
  { text: 'About Us', link: '/about' },
  { text: 'Contact Us', link: '/contact-us' },
  { text: 'Privacy Policy', link: '/privacy-policy' },
  { text: 'Refund & Cancellation Policy', link: '/refund-cancellation' },
  { text: 'Terms & Conditions', link: '/term-conditions' },
];

export default {
  name: 'more-menus',
  components: {
    'van-popup': Popup,
  },
  data() {
    return {
      menuInfos,
      visiable: false,
    };
  },
  methods: {
    handleTriggerPopup(visiable) {
      this.visiable = visiable;
    },
    handleLinkTo(link) {
      this.$router.push({ path: link });
    },
  },
};
</script>

<style lang="less" scoped>
.mm-icon {
  top: 43px;
  right: 40px;
  width: 34px;
  height: 34px;
  position: fixed;
  background: url('../assets/more_menus.png') no-repeat center / 100%;
}
.mm-popup {
  height: 100%;
  width: 367px;
  padding: 50px 45px;
  box-sizing: border-box;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mm-popup-list-item {
  color: #FFFFFF;
  margin-bottom: 38px;
  font: 500 14px PingFang SC-Medium, PingFang SC;

  &:hover {
    cursor: pointer;
  }
}
.mm-popup-footer {
  color: #A7A7A7;
  text-align: center;
  font: 600 14px PingFang TC-Semibold, PingFang TC;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 500px) {
  .mm-icon {
    top: 0.4rem;
    right: 0.4rem;
    width: 0.48rem;
    height: 0.48rem;
  }
  .mm-popup {
    width: 4.18rem;
    padding: 0.6rem 0.4rem;
    box-sizing: border-box;
  }
  .mm-popup-list-item {
    margin-bottom: 0.48rem;
    font: 500 0.24rem PingFang SC-Medium, PingFang SC;
  }
  .mm-popup-footer {
    font: 600 0.24rem PingFang TC-Semibold, PingFang TC;
  }
}
</style>
