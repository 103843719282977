const baseURL =
    process.env.NODE_ENV === "production"
        ? "https://api.woolookme.com" : "https://test-api.woolookme.com";
// ? "https://api.woolookme.com" :"http://localhost:8080/"
// ? "https://test-api.woolookme.com" :"https://test-api.woolookme.com";

const GOODS_TYPE = {
    DIAMOND: 1,
    VIP: 2,
};


export {baseURL, GOODS_TYPE};
