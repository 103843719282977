<template>
  <div class="pay-success app-adapted-wrap">
    <div class="ps-status">
      <img class="ps-status-img" src='../img/succ.png'>
      <h2>operation success</h2>
    </div>
    <div class="ps-button-wrap">
      <Button class="ps-button" block type="danger" @click="onOk">ok</Button>
    </div>
  </div>
</template>

<script type="text/javascript">
import { Button } from 'vant';

export default {
  name: 'PaySuccess',
  components: {
    Button
  },
  methods: {
    onOk(){
      this.$router.push({ path: "/goods", query: JSON.parse(localStorage.getItem("pay_info")) });
    }
  }
}
</script>

<style lang="less" scoped>
.ps-status {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  flex-direction: column;

  h2 {
    margin-top: 0.1rem;
  }
}
.ps-status-img {
  width: 3rem;
}
.ps-button-wrap {
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}
.ps-button {
  width: 2rem;
  height: 0.8rem;
  border-radius: 0.4rem;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 500px) {
  .ps-status-img {
    width: 2rem;
  }
}
</style>