import axios from "./index";

const getApp = () => {
  return axios.request({
    url: "/account/user/web-show-apps",
    method: "post",
  });
};
const checkAccount = (data = { account: '', app: '' }) => {
  return axios.request({
    url: "/account/user/web-check",
    method: "post",
    data: data,
  });
};
export { getApp,checkAccount };
